import React from "react"
import MarketoForm from "../MarketoForm"

class GatesWithForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gatesVisible: false,
    }
    this.triggerOnSuccess = this.triggerOnSuccess.bind(this)
  }

  componentDidMount() {
    // console.log("FormID:" + this.props.marketoFormId)
    // console.log("Key:" + this.props.resourceKey)
    const setVisibility = localStorage.getItem(this.props.resourceKey) !== "true"
    this.setState({ gatesVisible: setVisibility })
  }

  triggerOnSuccess() {
    this.setState({ gatesVisible: false })
    localStorage.setItem(this.props.resourceKey, "true")
    // console.log("Gated hidden on success")
  }

  render() {
    return this.state.gatesVisible ? (
      <div className="GatesWithForm">
        <h3>{this.props.slogan}</h3>
        <MarketoForm
          formId={this.props.marketoFormId || `1571`}
          triggerOnSuccess={this.triggerOnSuccess}
          resourceKey={this.props.resourceKey}
        />
      </div>
    ) : (
      <div className="GatesWithForm uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        {this.props.children}
      </div>
    )
  }
}
export default GatesWithForm
