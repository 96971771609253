import React from "react"
import { Image } from "../VindiciaComponents"

const TwoColumnWithImageSlider = ({ data }) => {
  const section = data.primary

  const collapsible = label => {
    return {
      collapseStyle: label.includes("collapse")
        ? `uk-position-z-index uk-flex uk-flex-middle uk-padding-small background-pink uk-light`
        : null,
      position: label.includes("collapse") ? { marginLeft: "-200px" } : null,
    }
  }
  return (
    <section id={section.section_id} className="uk-section">
      <div className={`uk-container two-column-with-image`}>
        <div className="uk-grid-large uk-flex-middle" data-uk-grid>
          <div
            className="uk-text-center uk-width-2-5@m"
            tabIndex="-1"
            data-autoplay-interval={10000}
            data-uk-slider="autoplay: true"
          >
            <div className="uk-position-relative slider-arrow-wrapper">
              <div className="uk-slider-container uk-position-relative">
                <ul className="uk-slider-items uk-child-width-1-1 " data-uk-grid>
                  {data.items.map((slide, i) => (
                    <Image
                      src={slide.image.fixed.src}
                      srcSet={slide.image.fixed.srcSet}
                      alt="Vindicia Retain"
                      width={slide.image.fixed.width}
                      height={slide.image.fixed.height}
                      style={{ objectFit: "contain" }}
                    />
                  ))}
                </ul>
                <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin">
                  <li></li>
                </ul>
              </div>
              <a
                className="uk-position-center-left uk-position-small uk-hidden-hover"
                href="#"
                data-uk-slidenav-previous
                data-uk-slider-item="previous"
              ></a>
              <a
                className="uk-position-center-right uk-position-small uk-hidden-hover"
                href="#"
                data-uk-slidenav-next
                data-uk-slider-item="next"
              ></a>
            </div>
          </div>
          <div
            className={`uk-width-expand uk-flex uk-flex-middle ${data.slice_label &&
              data.slice_label.includes("image-right") &&
              "uk-flex-first@m"} ${data.slice_label &&
              collapsible(data.slice_label).collapseStyle}`}
          >
            <div>
              {section.copy && <div dangerouslySetInnerHTML={{ __html: section.copy.html }} />}
              {section.cta_url && section.cta_url.url && section.cta_text && (
                <a
                  className="uk-button uk-border-pill"
                  href={section.cta_url.url}
                  target={section.cta_url.target}
                >
                  {section.cta_text}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TwoColumnWithImageSlider
