import React from "react"

const FAQ = ({ data }) => {
  const { primary: section, items } = data

  return (
    <section id={section.section_id} className="uk-section">
      <div className="uk-container">
        <div className="uk-grid-large" data-uk-grid>
          <div className="uk-width-1-3@m">
            {section.heading && <div dangerouslySetInnerHTML={{ __html: section.heading.html }} />}
            {section.description && (
              <div dangerouslySetInnerHTML={{ __html: section.description.html }} />
            )}
            {section.button_title && (
              <a
                className="uk-button uk-border-pill"
                href={section.button_link.url}
                target={section.button_link.target}
              >
                {section.button_title}
              </a>
            )}
          </div>
          <div className="uk-width-2-3@m">
            <ul uk-accordion="collapsible: true" className="uk-accordion faq-accordion">
              {items.map((item, i) => (
                <li key={`item-${i}`}>
                  <h3 className="uk-accordion-title uk-margin-remove">{item.question}</h3>
                  <div
                    className="uk-accordion-content"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ
