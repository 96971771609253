import React from "react"
import { Image } from "../VindiciaComponents"

const RelatedVideo = data => {
  return (
    <div className="related-post">
      <a className="uk-display-block" href={data.video_link.url}>
        <Image
          src={data.video_image.fluid.src}
          srcSet={data.video_image.fluid.srcSet}
          alt={data.video_image.alt}
          style={{
            height: "240px",
            width: "100%",
            objectFit: "cover",
          }}
        />
        <div className="uk-text-left" dangerouslySetInnerHTML={{ __html: data.video_title.html }} />
      </a>
    </div>
  )
}

const Video = ({ data }) => {
  const { primary: section, items } = data
  const vimeoId = `https://player.vimeo.com/video/${section.vimeo_id_video}`
  return (
    <section
      id={section.section_id}
      className="video-section uk-section uk-container uk-text-left uk-flex uk-flex-wrap uk-flex-between"
    >
      <div className="main-column uk-card">
        <div
          className="uk-text-left"
          dangerouslySetInnerHTML={{ __html: section.main_column_title.html }}
        />
        <div className="vimeo-wrapper">
          <iframe
            src={vimeoId}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div
          className="uk-text-left"
          dangerouslySetInnerHTML={{ __html: section.main_video_description.html }}
        />
      </div>
      <div className="secondary-column uk-card">
        <div
          className="uk-text-left"
          dangerouslySetInnerHTML={{ __html: section.secondary_column_title.html }}
        />
        {items.map((video, i) => {
          return <RelatedVideo {...video} key={i} />
        })}
      </div>
    </section>
  )
}

export default Video
