import React from "react"

const GuideBlock = ({ data, title }) => {
  return (
    <section className={"uk-section"}>
      <div className="uk-container">
        <div className="uk-grid-large" data-uk-grid>
          <div className="uk-width-1-3@m uk-flex-column">
            <div
              className="uk-flex uk-flex-column"
              uk-sticky={"offset: 100; bottom: true"}
              uk-scrollspy-nav="closest: a; scroll: true; offset: 120"
              style={{ zIndex: 970 }}
            >
              <h2 className="no-lane">{title}</h2>
              {data.map(guide => (
                <a
                  className="uk-link-text"
                  href={`#${guide.title.text.replace(/\s/g, "")}`}
                  data-uk-smooth-scroll="{offset: 90}"
                  uk-scroll={"offset: 100"}
                >
                  {guide.navigation_text}
                </a>
              ))}
            </div>
          </div>
          <div className={`uk-width-2-3@m`}>
            {data.map(guide => (
              <div id={guide.title.text.replace(/\s/g, "")}>
                <h2>{guide.title.text}</h2>
                <div dangerouslySetInnerHTML={{ __html: guide.description.html }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default GuideBlock
