import React from "react"
import OneColumn from "../Content/OneColumn"
import CTABlock from "../Content/CTABlock"
import TwoColumnWithImage from "../Content/TwoColumnWithImage"
import TwoColumnWithVideo from "../Content/TwoColumnWithVideo"
import TwoColumnWithAccordion from "../Content/TwoColumnWithAccordion"
import FAQ from "../Content/FAQ"
import FullWidthBanner from "../Content/FullWidthBanner"
import FullWidthBannerWithVideoOverlay from "../Content/FullWidthBannerWithVideoOverlay"
import ThreeColumnWithIcon from "../Content/ThreeColumnWithIcon"
import ThreeColumnGrid from "../Content/ThreeColumnGrid"
import ThreeColumnCards from "../Content/ThreeColumnCards"
import SliderWithBackground from "../Content/SliderWithBackground"
import Testimonials from "../Product/Testimonials"
import PromotionalComponent from "../Content/PromotionalComponent"
import ImageGallery from "../Content/ImageGallery"
import NumberFact from "../Content/NumberFact"
import Video from "../Content/Video"
import RelatedMaterial from "../Content/RelatedMaterial"
import HoverBackgroundZoom from "../Content/HoverBackgroundZoom"
import TwoColumnWithImageSlider from "../Content/TwoColumnWithImageSlider"
import Form from "../Content/Form"
import { Image } from "../VindiciaComponents"
import LogoCarousel from "../Content/LogoCarousel"
import FourColumnWithIcon from "../Content/FourColumnWithIcon"
import ThreeColumnIconCard from "../Content/ThreeColumnIconCard"
import OneColumnScroller from "../Content/OneColumnScroller"

const ContentType = ({ section }) => {
  const type = section.slice_type

  switch (type) {
    case "1-column_container-small":
      return <OneColumn data={section} />
    case "2-column":
      return <TwoColumnWithImage data={section} />
    case "2-column-video":
      return <TwoColumnWithVideo data={section} />
    case "2-column_with_accordion":
      return <TwoColumnWithAccordion data={section} />
    case "full_width_banner":
      return <FullWidthBanner data={section} />
    case "full_width_banner_with_video_overlay":
      return <FullWidthBannerWithVideoOverlay data={section} />
    case "3-column_with_icon":
      return <ThreeColumnWithIcon data={section} />
    case "3-column_grid":
      return <ThreeColumnGrid data={section} />
    case "scroller_with_bg":
      return <SliderWithBackground data={section} />
    case "3-column-cards":
      return (
        <ThreeColumnCards data={section}>
          {section.items.map((item, i) => {
            const styles = {
              boxShadow: "none",
              borderRadius: "10px",
              backgroundColor: section.primary.card_background_color || "#fff",
            }

            return (
              <li key={`card-${i}`}>
                <div
                  className="uk-card uk-card-body uk-card-small"
                  style={{ ...styles }}
                  data-uk-card
                >
                  <div className="uk-card-media-top uk-text-center uk-margin-small-bottom">
                    {item.card_header_image.fixed && (
                      <Image
                        src={item.card_header_image.fixed.src}
                        srcSet={item.card_header_image.fixed.srcSet}
                        alt="Vindicia"
                      />
                    )}
                  </div>
                  <div className="uk-text-center">
                    <div dangerouslySetInnerHTML={{ __html: item.card_header_content.html }} />
                    <div dangerouslySetInnerHTML={{ __html: item.card_body_content.html }} />
                  </div>
                </div>
              </li>
            )
          })}
        </ThreeColumnCards>
      )
    case "cta_block":
      return <CTABlock data={section} />
    case "number_fact":
      return <NumberFact data={section} />
    case "related_material_component":
      return <RelatedMaterial data={section} />
    case "promotion_component":
      return <PromotionalComponent data={section} />
    case "image_gallery":
      return <ImageGallery data={section} />
    case "testimonials":
      return (
        <Testimonials
          data={{
            testimonials_image: section.primary.testimonials_image,
            testimonial_quote: section.items,
          }}
        />
      )
    case "video_component":
      return <Video data={section} />
    case "hover_background_zoom_block":
      return <HoverBackgroundZoom data={section} />
    case "column_with_image_slider":
      return <TwoColumnWithImageSlider data={section} />
    case "form":
      return <Form data={section} />
    case "faq":
      return <FAQ data={section} />
    case "logo_carousel":
      return <LogoCarousel data={section} />
    case "4-column-icon":
      return <FourColumnWithIcon data={section} />
    case "3-column-icon-cards":
      return <ThreeColumnIconCard data={section} />
    case "1-column-repeatable":
      return <OneColumnScroller data={section} />
    case "embed_code":
      return (
        <section className="uk-section uk-container">
          <div key={section.primary.section_id}>
            <div dangerouslySetInnerHTML={{ __html: section.primary.embed_code }}></div>
          </div>
        </section>
      )

    default:
      return <></>
  }
}

export default ContentType
