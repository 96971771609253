import React from "react"
import GatesWithForm from "../Resources/GatesWithForm"

const Form = ({ data }) => {
  const {
    form_title,
    form_description,
    link,
    marketo_form_id,
    link_title,
    section_id,
  } = data.primary

  return (
    <section
      id={section_id}
      className="form-section uk-section uk-container uk-text-left uk-flex uk-flex-wrap uk-flex-between"
    >
      <div className="uk-container uk-container-small uk-grid uk-width-1-1">
        <div className="uk-width-1-1 uk-width-1-2@s uk-flex uk-flex-center uk-flex-middle uk-flex-column">
          {form_title && <div dangerouslySetInnerHTML={{ __html: form_title.html }} />}
          {form_description && <div dangerouslySetInnerHTML={{ __html: form_description.html }} />}
        </div>
        <div className="uk-width-1-1 uk-width-1-2@s">
          <GatesWithForm
            slogan=""
            marketoFormId={marketo_form_id || 1569}
            resourceKey={`form_${marketo_form_id}`}
          >
            <h3 className="uk-text-bold">Thank you</h3>
            <a
              className="uk-button uk-button-medium uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
              href={link.url}
              target={link.target}
            >
              {link_title}
            </a>
          </GatesWithForm>
        </div>
      </div>
    </section>
  )
}

export default Form
