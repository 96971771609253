import React, { useEffect } from "react"

const MarketoForm = ({ resourceKey, formId, domainAddr, triggerOnSuccess }) => {
  const marketoScriptId = `mktoForms_${resourceKey}`

  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript()
    }
  }, [])

  useEffect(() => {
    loadForm()
  }, [formId])

  const loadForm = () => {
    setTimeout(() => {
      if (typeof window.MktoForms2 !== "undefined") {
        let domain = domainAddr || "//info.vindicia.com"
        window.MktoForms2.loadForm(domain, "122-AYM-013", formId, function(form) {
          // Add an onSuccess handler
          form.onSuccess(function(values, followUpUrl) {
            localStorage.setItem("key_to_gated", "true")

            if (typeof triggerOnSuccess === "function") {
              triggerOnSuccess()
            }

            if (followUpUrl) {
              window.location.href = followUpUrl
            }

            return false
          })
        })
      } else {
        loadForm()
      }
    }, 100)
  }

  const loadScript = () => {
    let s = document.createElement("script")
    s.id = marketoScriptId
    s.type = "text/javascript"
    s.async = true
    s.src = "//app-aba.marketo.com/js/forms2/js/forms2.min.js"
    document.getElementsByTagName("head")[0].appendChild(s)
  }

  return (
    <div>
      <form id={`mktoForm_${formId}`} className="marketoForm" />
    </div>
  )
}

export default MarketoForm
